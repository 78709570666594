






import { Component, Vue, Watch, ProvideReactive } from 'vue-property-decorator';
import FlowViewer from '@/views/home/flow2/flow-viewer.vue';
import { readRuntimeTask } from '@/api/flow-design/FlowApi';

@Component({
  name: 'UserHomeFlowPossibility',
  components: {
    FlowViewer
  }
})
export default class UserHomeFlowPossibility extends Vue {
    //点击流程节点 右侧属性配置
  @ProvideReactive() flowGraphDisable: any = 'top'; //top--表示从顶级禁用  partEnable--从组件中定义：表示挂载在该组件下的‘部分’启用

  operation: string = 'task_possibility';
  isShow: boolean = false;

  get _taskDataId() {
    return this.$route.query.id;
  }

  private created(): void {
    this.$nextTick(() => {
      this.isShow = true;
    });
  }

  @Watch('_taskDataId')
  taskDataIdWatcher(val) {
    this.isShow = false;
    this.$nextTick(() => {
      this.isShow = true;
    });
  }
}
